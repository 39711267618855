import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />

    <div className="flex-grow  flex flex-col text-center items-center container">
        <div className="flex-shrink-0 my-auto py-16 sm:py-32">
          <p className="text-sm font-semibold text-blue-500 uppercase tracking-wide">404 error</p>
          <h1 className="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl">Page not found</h1>
          <p className="mt-2 text-base text-gray-300">Sorry, we couldn’t find the page you’re looking for.</p>
          <div className="mt-6">
            <a href="#" className="text-base font-medium text-blue-500 hover:text-blue-400">
              Go back home<span aria-hidden="true"> &rarr;</span>
            </a>
          </div>
        </div>
      </div>
  </Layout>
)

export default NotFoundPage
